import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const GardaSikring = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Garda Sikring",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #ec2f22, #e22e22, #d72c20, #d92b20, #d02a1f )",
        icons: "#ec2f22",
        navClass: "garda",
        ogImage: require("../../assets/img/portfolio/garda_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/garda-sikring/",
      }}
    >
      <PortfolioHeader name="garda" height="214" />
      <section className="container-fluid garda-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Garda Sikring</h1>
              <ul>
                <li>Strony internetowe</li>
                <li>Integracje systemowe</li>
                <li>Rozbudowane formularze kontaktowe</li>
                <li>Stopki e-mail</li>
                <li>Bieżąca obsługa i administracja stroną www</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Garda Sikring to norweski lider rynku w zakresie ochrony
                obwodowej. Firma dostarcza i instaluje stałe oraz tymczasowe
                rozwiązania tj. barierki, bramy, siatki, ogrodzenia itp. Nasza
                współpraca trwa już od początku 2019 roku i w tym czasie
                wypracowaliśmy wspólnie kilkanaście stron internetowych,
                integracje z systemem CRM którego używa firma, zaprojektowaliśmy
                i zakodowaliśmy zaawansowane formularze kontaktowe oraz stopki
                e-mailowe.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-2">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/garda_full.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-3">
        <div className="row">
          <div className="col-lg-5 offset-lg-1">
            <img
              src={require("../../assets/img/portfolio/garda_main_1.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-4 offset-lg-1">
            <div className="inner">
              <h3>Strony internetowe</h3>
              <p>
                Zaprojektowaliśmy rozbudowaną stronę internetową firmyw
                nowoczesnym wydaniu. Strona pokazuje szeroki zakres usług firmy
                w zakresie ochrony obwodowej, zawiera rozbudowany katalog
                produktowy, a formularze kontaktowe zostały zintegrowane z
                systemem CRM klienta dla zwiększenia jakości obsługi. Cały czas
                rozwijamy stronę o dodatkowe funkcjonalności zgodnie z bieżącym
                zapotrzebowaniem naszego Klienta.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-4">
        <div className="row">
          <div className="col-md-10 text-right">
            <img
              src={require("../../assets/img/portfolio/garda_main_2.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-5">
        <div className="row">
          <div className="col-lg-4 offset-lg-1">
            <div className="inner">
              <p>
                Zgodnie z zapotrzebowaniem Klienta zaprojektowaliśmy spójne z
                identyfikacją wizualną strony internetowe spółek córek holdingu
                Garda Sikring tj. Nor-Netting czy Trygg Vei AS, które pozwoliły
                na zoptymalizowanie pracy poszczególnych oddziałów firmy w
                wybranych regionach Norwegii.
              </p>
            </div>
            <img
              src={require("../../assets/img/portfolio/garda_main_3.png")}
              className="img-fluid img_main_3"
              alt=""
            />
          </div>
          <div className="col-lg-5 offset-lg-2">
            <img
              src={require("../../assets/img/portfolio/garda_main_4.png")}
              className="img-fluid img_main_4"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-6">
        <div className="row">
          <div className="col-lg-4 offset-lg-1">
            <div className="inner">
              <h3>Stopki e-mail</h3>
              <p>
                Zaprojektowaliśmy dla naszego Klienta stopki e-mail, tak aby
                codzienna korespondencja była spójna z identyfikacją wizualną
                całej marki i przedstawiała potencjalnym kontrahentom, że lider
                rynku, którym niewątpliwie jest Garda Sikring dba o każdy
                szczegół zarówno jeśli chodzi o swój wizerunek, jak i obsługę
                swoich Klientów.
              </p>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <img
              src={require("../../assets/img/portfolio/garda_main_5.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-7">
        <div className="inner">
          <h3>Bieżące wsparcie i administracja serwisem www</h3>
          <p>
            Obecnie zajmujemy się zarządzaniem stroną firmy w ramach naszej
            usługi ForSecure (www.forsecure.pl), co wiąże się z jej regularnymi
            aktualizacjami, zabezpieczeniem, a także bieżącym serwisowaniem oraz
            dalszym rozwojem strony o nowe funkcjonalności na życzenie naszego
            partnera.
          </p>
          <img
            src={require("../../assets/img/forsecure.png")}
            className="img-fluid"
            alt=""
          />
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default GardaSikring;
